import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Main from './components/Main.js'
import Features from './components/Features.js'
import About from './components/About.js'
import Subscribe from './components/Subscribe.js'
import SideBar from "./Sidebar";
import Helmet from 'react-helmet';

class App extends Component {

  showSettings(event) {
    event.preventDefault();

  }

  render() {
    return (
      <div id="App" className="App">
      <Helmet title="Prodex - Система контроля для швейных фабрик" />
        <div className="d-md-none">
          <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
        </div>
        <div id="page-wrap">
          <Main />
          <Features id="functionality" />
          <About id="about" />
          <Subscribe id="purchase" />
        </div>
      </div>
    );
  }
}

export default App;
