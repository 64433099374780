

import './Main.css'
import macbook from '../img/macbook-mock-dribbble2.png'

import React, { Component, useState } from 'react';



function Main(props) {


  
  const [selectedLink, setSelectedLink]=useState("main");

  return (
    <div className="mainDiv" id="main" >

      <div className="container-fluid">

        {/* <div className="prodexMobile">Prodex</div> */}

        <div className="prodexCaption">Prodex</div>
        <ul className="d-none d-md-block">
        
        <li><button onClick={()=>{window.location="https://app.prodex.uz"}} className="login" >Войти</button></li>
        <li><div  class="phoneNumber">+998974901777</div></li>
          <li><a onClick={()=>setSelectedLink("purchase")} className={selectedLink=="purchase"? "isSelected":""}  href="#purchase">Подключиться</a></li>
          <li><a onClick={()=>setSelectedLink("about")} className={selectedLink=="about"? "isSelected":""} href="#about">О нас</a></li>
          <li><a onClick={()=>setSelectedLink("functionality")} className={selectedLink=="functionality"? "isSelected":""} href="#functionality">Возможности</a></li>
          <li><a onClick={()=>setSelectedLink("main")} className={selectedLink=="main"? "isSelected":""} href="#main">Главная</a></li>
        </ul>
        
        <div className="mainRow">
          <div className="row">
            <div className="col-12 col-md-6 align-self-center">
            <div className="leftDiv">
              <div className="mainHeader">
                Все еще ведете учет на бумажках?
           </div>
              <div className="belowMainHeader">
                Prodex - система контроля производства для швейных фабрик, которая даёт вам моментальную аналитику по всему производственному процессу
            </div>
              <div className="buttonDiv">
                {/* <div className="tryButton">
                <div className="tryButtonText">Попробовать бесплатно</div>
              </div> */}
              <a href="#purchase">
                <button className="tryButton">Попробовать бесплатно</button>
                </a>
              </div>
            </div>
            </div>
            <div className="col-12 col-md-6 d-none d-md-block align-self-center ">
              {/* <div className="macbookDiv d-sm-none d-md-block">
              <div className="macbookImage">
              </div>
            </div> */}
              <figure class="figure">
                <img src={macbook} class="figure-img img-fluid" style={{ width: '100%', height: 'auto' }} />

              </figure>

            </div>
          </div>
        </div>
      </div>



      <div className="mouseDiv d-none d-md-block"></div>
    </div>
  )

}

export default Main;