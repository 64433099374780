

import './Features.css'
import cuttingRoom from '../img/cuttingRoom.png'
import stockRoom from '../img/stockRoom.png'
import employee from '../img/employee.png'
import leftYellow from '../img/settings.png'
import rightYellow from '../img/rightYellow.png'
import React, { Component } from 'react';

function Main(props) {

  return (
    <div className="secondDiv" id="functionality">
      <div className="container">
        <div className="secondRow">
          <div className="row">
            <div className="col-md-12">
              <div className="believeIt"></div>
              <div className="dontBeLikeOthers">Не будьте как все! Примите новый подход к любимому делу!</div>
            </div>
          </div>


          <div className="thirdRow">
            <div className="row">

              <div className="col-md-8 align-self-center mb-4">
                <div className="instantReport">Мгновенный отчёт по состоянию фабрики</div>
                <div className="instantReportDescription">Система покажет вам общую картину по производству. 
                Вы контролируете расход материала, знаете что кроится, что шьётся и когда будет завершена вся партия.
                </div>
              </div>
              <div className="col-md-4 align-self-center mb-4">
                <figure class="figure">
                  <img src={cuttingRoom} class="figure-img img-fluid rounded" style={{ width: 400, height: 'auto' }} />

                </figure>

              </div>


            </div>
          </div>

          <div className="fourthRow">
            <div className="row">

              <div className="col-md-8 align-self-center mb-4">
                <div className="employeeControl">Персональный контроль сотрудника</div>
                <div className="employeeControlDescription">Индивидуальная статистика швеи по изготовленным моделям. Полный отчёт по прогулам и посещаемости
                </div>
              </div>

              <div className="col-md-4 align-self-center order-md-first mb-4">
                <figure class="figure">
                  <img src={employee} class="figure-img img-fluid rounded" style={{ width: 400, height: 'auto' }} />

                </figure>

              </div>

            </div>
          </div>

          <div className="fifthRow">
            <div className="row">

              <div className="col-md-8 align-self-center mb-4">
                <div className="stock">Вы видите фактические остатки на складах, планируете и экономите на закупках.</div>
                <div className="stockDescription">Вы будете знать сколько и какого материала имеется на складе, чтобы вы не заказывали лишнего
                </div>
              </div>
              <div className="col-md-4 align-self-center mb-4">
                <figure class="figure">
                  <img src={stockRoom} class="figure-img img-fluid rounded" style={{ width: 400, height: 'auto' }} />

                </figure>

              </div>

            </div>
          </div>

          <div className="tryAgainDiv">
          <a href="#purchase">
            <button className="secondTryButton">Попробовать бесплатно</button>
            </a>
          </div>

          <div className="sixthRow">
            <div className="row ">

              <div className="col-md-6 flex-column mb-4">
                <div className="yellowDiv">

                  <div className="row">

                    <div className="col-md-2 align-self-center">
                      <figure class="figure ">
                        <img src={leftYellow} class="figure-img img-fluid rounded " style={{ width: 96, height: 'auto' }} />

                      </figure>
                      
                    </div>

                    <div className="col-md-10 align-self-center">
                      <div className="leftYellowCaption">Автоматизируйте вашу текстильную фабрику.</div>
                      <div className="leftYellowDescription">Наша система освободит 90% вашего времени, чтобы вы смогли наслаждаться жизнью.</div>

                    </div>

                  </div>

                </div>

              </div>
              
              <div className="col-md-6 d-flex h-100 mb-4">
                <div className="yellowDiv">
                <div className="row">

                  <div className="col-md-2 align-self-center">
                    <figure class="figure ">
                      <img src={rightYellow} class="figure-img img-fluid rounded " style={{ width: 96, height: 'auto' }} />

                    </figure>
                    
                  </div>

                  <div className="col-md-10 align-self-center">
                    <div className="leftYellowCaption">Доступ к системе в любом удобном для вас месте. </div>
                    <div className="leftYellowDescription">Вы можете удалённо подключаться и управлять фабрикой с компьютера или планшета</div>

                  </div>

                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  )

}

export default Main;