

import './Subscribe.css'
import React, { Component, useState } from 'react';



const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


function Main(props) {

  const [formData, setFormData] = useState({ name: "", phone: "" })



  const handleSubmit = e => {

    if (formData.name.trim().length === 0) {
      alert("Введите имя")
      e.preventDefault();
      return;
    }
    if (formData.phone.trim().length === 0) {

      alert("Введите телефон")
      e.preventDefault();
      return;
    }

    if (formData.name.trim().length > 0 && formData.phone.trim().length > 0) {
      //console.log("form is this",this.encode({ "form-name": "contact", ...formData }));
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formData })
      })
        .then(() => alert("Ваша заявка успешно принята!"))
        .catch(error => alert(error));

    }

    e.preventDefault();
  };

  const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <div id="purchase">
      <div className="purchaseDiv">
        <div className="container">

          <div className="row">
            <div className="col-md-6">
              <div className="saveYourTime" >Мы экономим ваше время и деньги!</div>
              <div className="tryForFree" >Подключитесь бесплатно!</div>
              <div className="whatWeOffer" >Мы предлагаем вам внедрение системы, обучение персонала и пробный (испытательный) месяц совершенно бесплатно!</div>
            </div>


            <div className="col-md-6">
              <form onSubmit={handleSubmit} name="contact" method="POST" data-netlify-recaptcha="true" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <input type="text" name="name" className="yourName" placeholder="Ваше имя" onChange={handleChange} />
                <input type="tel" name="phone" title="Пожалуйста введите правильный номер" className="yourPhone" placeholder="Ваш телефон" onChange={handleChange} />

                <button type="submit" className="finalBuyButton float-right">Отправить заявку</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footerDiv">
        <div className="container">

          <div className="row">

            <div className="col-md-3 mb-4">
              <div className="footerProdexCaption ">Prodex</div>
              <div className="footerCopyright">2019 Prodex.uz Все права защищены</div>
            </div>

            <div className="col-md-3 mb-4">
              <div className="emailCaption">E-MAIL</div>
              <div className="emailContent">sales@prodex.uz</div>
            </div>

            <div className="col-md-3 mb-4">
              <div className="emailCaption">Номер телефона</div>
              <div className="emailContent">+998974901777</div>
            </div>

            <div className="col-md-3 mb-4">
              <div className="emailCaption">Адрес</div>
              <div className="emailContent">Ул. Пушкина, 79, 27</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Main;