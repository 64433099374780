

import './About.css'
import cuttingRoom from '../img/cuttingRoom.png'
import stockRoom from '../img/stockRoom.png'
import employee from '../img/employee.png'
import quotes from '../img/quotes.png'
import mypicture from '../img/mypicture.jpg'
import person from '../img/person-ico.png'
import president from '../img/president2.jpeg'
import React, { Component } from 'react';


function Main(props) {

  return (
    <div id="about">
      <div className="container">
        <div className="aboutUs">

          <div className="row">
            <div className="col-md-8">
              <div className="aboutUsCaption">О НАС</div>
              <div className="whyCaption">История создания Prodex</div>
            </div>
          </div>

          <div className="aboutUsRow">
            <div className="row">

              <div className="col-md-4 mb-4 ">

                <figure className="figure float-right">
                  <img src={mypicture} className="figure-img img-fluid roundedImg" style={{ width: 251, height: 'auto' }} />

                </figure>
              </div>

              <div className="col-md-8 mb-4 order-md-first">
                <div className="row">
                  <div className="col-md-2">
                    <figure class="figure">
                      <img src={quotes} class="figure-img img-fluid rounded" style={{ maxWidth: 62, height: 'auto' }} />

                    </figure>
                  </div>
                  <div class="col-md-10">
                    <div className="aboutUsContent">
                      Идея создать систему Prodex возникла во время управления фабрикой "Milana Textile". 
                      Я понял, что трачу 90% времени на отчёты, проверки, слежение за заказами и на расчёт себестоимости. Работая ежедневно, до поздней ночи, мне всё равно не хватало времени на развитие бизнеса и стратегическое планирование. Не было точной информации о готовности партии, была путаница с остатками и даже три бухгалтера не могли дать точных цифр по выработке и зарплате. 
                      Prodex решил все эти проблемы и многократно повысил скорость и точность показателей.
                </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="aboutUsRow2">
            <div className="row">

              <div className="col-md-4 mb-4 ">

                <figure className="figure float-right">
                  <img src={president} className="figure-img img-fluid rounded" style={{ width: 251, height: 'auto' }} />

                </figure>
              </div>

              <div className="col-md-8 mb-4 order-md-first">
                <div className="row">
                  <div className="col-md-2">
                    <figure class="figure">
                      <img src={quotes} class="figure-img img-fluid rounded" style={{ maxWidth: 62, height: 'auto' }} />

                    </figure>
                  </div>
                  <div class="col-md-10">
                    <div className="aboutUsContent">
                    В мае 2018 года нашу фабрику посетил президент Шавкат Миромонович Мирзиёев. Лично ознакомившись со всеми нововведениями и преимуществами системы Prodex, дал высокую оценку её эффективности и точности. 
                    Теперь и вы можете оценить достоинства и освободить своё время !
                </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="authorRow">
            <div className="row">



              <div className="col-md-8 mb-4 order-md-first">
                <div className="row">
                  <div className="col-md-2">
                    <figure class="figure">
                      <img src={person} class="figure-img img-fluid rounded" style={{ maxWidth: 62, height: 'auto' }} />

                    </figure>
                  </div>
                  <div class="col-md-10">
                    <div className="authorName">
                      Amir Atabekov
                </div>
                <div className="authorTitle">
                      Сооснователь Milana Textile
                </div>
                  </div>
                </div>
              </div>


            </div>
          </div>







        </div>
      </div>

    </div>
  )

}

export default Main;